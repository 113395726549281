// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$backgroundColor: #EFF7FF;
$hoverColor: #E7EEF6;
$navBarColor: #0A5EAD;
$activeColor: #D3E5F5;
$buttonCollor: #107EDB;
$buttonHoverCollor: #0067bd;
$fontColor: #424242;
$iconColor: #757575;
$viewModeColor: #d1edff;
$editModeColor: #dffcbf;
$subCancelColor: #e0e0e0;
$inputBorderThinColor: #e0e0e0;
$inputBorderColor: #939393;

$navBarHeight: 3.0rem;
$headerExtensionHeight: 2.5rem;

$sideBarWidth: 260px;

$cardItemWidth: 300px;

$errorColor: #ff5252;
$kdSuccessColor: #4caf59;