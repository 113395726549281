.alert-wrapper {
    position: absolute;
    text-align: right;
    padding-right: 2rem;
    left: auto;
    width: 100%;
    z-index: 100;

    #alert-kd-success {
        background-color: $kdSuccessColor;
        color: white;
        border-radius: 0.2rem;
        padding: 0.75rem;
        display: inline-block;
        min-width: 400px;
        text-align: left;
        line-height: 1.25rem;

        &.fadeout {
            animation: fadeout-animation 1s ease 0s 1 forwards;
        }
    }

    #alert-kd-error {
        background-color: $errorColor;
        color: white;
        border-radius: 0.2rem;
        padding: 0.75rem;
        display: inline-block;
        min-width: 400px;
        text-align: left;
        line-height: 1.25rem;

        &.fadeout {
            animation: fadeout-animation 1s ease 0s 1 forwards;
        }
    }
}

@keyframes fadeout-animation {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}