.sidebar-link {
    display: block;
    text-decoration: none;
    color: $fontColor;
    padding-left: 1.0rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    

    &:hover {
        color: $fontColor;
        background-color: $hoverColor;
        text-decoration: none;
        cursor: pointer;
    }

    &.is-active {
        color: $navBarColor;
        background-color: $activeColor;
    }
}

#sidebar-table {
    tr {
        background-color: transparent !important;
    }

    th {
        border: none !important;
    }

    td {
        padding: 0;
    }

    border: none !important;
}
