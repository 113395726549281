// Fonts

// Variables
@import 'variables';
@import 'header';
@import 'sidebar';
@import 'common';
@import 'datatable';
@import 'alert';
@import 'shains';

.container-wrap{
    .form-control{
        border: none;
        border-bottom: solid 1px;
        border-radius: unset;
        background: transparent;
        font-size: 15px;
    }
}
.banner{
    height: 40px;
    background: #fff;
    padding-left: 10px;
    .title{
        h2{
            margin-bottom: 0;
            font-size: 1.1rem;
            line-height: 40px;
        }
        a{
            padding: 10px;
            height: 100%;
            color: #000;
        }
    }
}
.list{
    background : #fff;
    .list-wrap{
        max-width: 800px;
        margin: 0 auto;
        button.close{
            margin: 0 0 0 auto;
        }
        p.title{
            font-size: 25px;
        }
    }
}
.inspection-alart{
    margin-top: 90px;
}
.inspection{
    table{
        thead{
            background-color: #EFF7FF;
        }
        th{
             font-size: 14px;
        }
        td{
             font-size: 16px;
        }
        th,td{
            height: 40px;
            border: thin solid rgba(0, 0, 0, 0.12);
            line-height: 18px;
            color: #424242;
        }
    }
}
.hide{
    display: none;
}
.btn-primary{
    background-color: #025DAD;
}
.btn-outline-primary{
    color: #025DAD;
    border-color: #025DAD;
}
.btn-outline-primary:hover{
    color: #fff;
    background: #025DAD;
}
.common-btn{
    font-size: 0.75rem;
    padding: 3px 20px;
}

.banner {
    height: 40px;
    background: #fff;
    padding-left: 10px;
}

.banner .title h2 {
font-size: 1.1rem;
line-height: 40px;
}

table{
 background: #fff;
}

.close-option {
    color: red;
    font-size: 20px;
}

.roll-call {
    margin-top: 80px;
    min-height: 80vh;
    background: #fff;
    table {
        font-size: 0.75rem;
        thead {
            background: #fff;
            background-color: #EFF7FF;

        }
        td {
             font-size: 16px;
            height: 40px;
            vertical-align: middle;
            padding: 5px;
        }
        th {
            font-size: 14px;
            font-weight: bold!important;
            vertical-align: middle!important;
            font-weight: unset;
            padding: 5px;
        }
    }
}

.roll-call-container{
    .btn{
        font-size: 0.75rem;
        padding: 3px 20px;
    }
}

.roll-call-form {
    max-width: 600px;
    .text-danger{
        font-size: 80%;
        padding: 5px;
        margin-bottom: 0;
    }
    .hide{
        display: none;
    }
}

.remodal{
    max-width: 500px!important;
    .remodal-close{
        right: 10px;
        left: unset;
        top: 10px;
    }
    .roll-call-result-form{
        align-items: self-end;
        margin-bottom: 0;
        .form-group{
            margin-bottom: 0;
            .border{
                padding: 10px;
                display: table;
                label{
                    display: table-cell;
                    vertical-align: middle;
                    margin-bottom: 0;
                }
                .form-control{
                    height: unset;
                }
                .custom-switch{
                    margin: 0 50%;
                    padding-left: 0;
                }
            }
        }
    }
}
.roll-call-register{
    margin-left: 80%;
}

.roll-call-output{
    table{
        thead {
            background: #fff;
            background-color: #EFF7FF;
        }
        td {
             font-size: 16px;
            height: 40px;
            vertical-align: middle;
            padding: 5px;
        }
        th {
            font-size: 14px;
            font-weight: bold!important;
            vertical-align: middle!important;
            font-weight: unset;
            padding: 5px;
        }
    }
}

