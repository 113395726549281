.btn-base {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    line-height: 1.6;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-green {
    @extend .btn-base;
    color: white;
    background-color: $buttonCollor;
    border-color: $buttonCollor;

    &:hover {
        color: white;
        background-color: $buttonHoverCollor;
        border-color: $buttonHoverCollor;
    }

    &:focus,&.focus {
        color: white;
        background-color: $buttonHoverCollor;
        border-color: $buttonHoverCollor;
        box-shadow: 0 0 0 0.2rem rgba(97, 157, 206, 0.5);
    }

    &:disabled,&.disabled {
        color: white;
        background-color: $buttonCollor;
        border-color: $buttonCollor;
    }
}

.btn-blue {
    @extend .btn-base;
    color: white;
    background-color: $buttonCollor;
    border-color: $buttonCollor;

    &:hover {
        color: white;
        background-color: $buttonHoverCollor;
        border-color: $buttonHoverCollor;
    }

    &:focus,&.focus {
        color: white;
        background-color: $buttonHoverCollor;
        border-color: $buttonHoverCollor;
        box-shadow: 0 0 0 0.2rem rgba(97, 157, 206, 0.5);
    }

    &:disabled,&.disabled {
        color: white;
        background-color: $buttonCollor;
        border-color: $buttonCollor;
    }
}

.icon-color {
    color: $iconColor;
}

html {
    height: 100%;
}

body {
    background-color: $backgroundColor;
    color: $fontColor;
    height: 100%;
}

.content-wrapper {
    padding-top: $navBarHeight + $headerExtensionHeight;
    background-color: white;
    height: 100%;
}

.content {
    margin-top: $navBarHeight;
    width: 100%;
}

.main-inner {
    background-color: white;
    padding-bottom: 6.0rem;
    position: relative;
}

.content-inner {
    max-width: 900px;
    padding: 2.0rem;
}

.input-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 2.5rem;
}

.input-wrapper {
    width: 100%;
    display: inline-block;
    position: relative;
}

.input-icon {
    width: 2.5rem;
    text-align: center;
    line-height: 1.0rem;
    padding: 0.25rem 0.5rem;
    padding-right: 0.5rem;
    font-size: 1.2rem;
    color: $iconColor;
    vertical-align: top;

    &.is-focused {
        color: $navBarColor;
    }
}

.label {
    position: absolute;
    top: 0px;
    left: 0px;
    color: grey;
    font-size: 1.0rem;

    transition: all 0.2s;

    &.is-filled {
        top: -15px;
        font-size: 0.8rem;
        line-height: 0.8rem;
        color: $fontColor;
    }
}

select.text-input:not(:disabled) {
    cursor: pointer;
}

select.text-input:disabled {
    opacity: 1.0;
    color: $fontColor;
}

.no-data-wrapper {
    position: absolute;
    display: inline-block;
    max-width: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    contain: content;
    will-change: transform;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    border-radius: 4px;
    top: -10px;
    background-color: white;
    min-width: 180px;
    padding: 16px;
    user-select: none;
    z-index: 100;
}

.text-input {
    width: 100%;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid $inputBorderThinColor;
    font-size: 1.0rem;
    outline: 0;
    background-color: transparent;
    transition: all 0.2s;

    &.is-invalid {
        border-color: $errorColor;
    }

    &:not(:disabled) {
        border-bottom: 1px solid $inputBorderColor;

        & + .label:hover {
            cursor: text;
        }
    }

    &[type="date"] + .label {
        top: -15px;
        font-size: 0.8rem;
        line-height: 0.8rem;
    }

    &:focus {
        border-bottom: 1px solid $navBarColor;

        & + .label {
            top: -15px;
            font-size: 0.8rem;
            line-height: 0.8rem;
            color: $navBarColor;
        }
    }

    &.invalid {
        border-color: $errorColor;

        & + .label.invalid {
            color: $errorColor;
        }
    }

    &:required + .label:after {
        content: "＊";
        color: $errorColor;
    }
}

.required::after {
    content: "＊";
    color: $errorColor;
}

#gender-wrapper {
    font-size: 1.0rem;

    &.invalid {
        color: $errorColor;
    }
}

.pointer {
    margin-left: 15px;

    &:hover {
        cursor: pointer;
    }

    input:hover {
        cursor: pointer;
    }

    label {
        margin-right: 0.5rem;
        &:hover {
            cursor: pointer;
        }
    }
}

input:disabled + label.file-label {
    border-bottom: 1px solid $inputBorderThinColor;
}

input:not(:disabled) + label.file-label:hover {
    cursor: pointer !important;
}

.file-label-wrapper.disabled {
    border-bottom: 1px solid $inputBorderThinColor;

}

.file-label-wrapper {
    transition: all 0.2s;
    border-bottom: 1px solid $inputBorderColor;

    &:not(.disabled):hover {
        cursor: pointer;
    }

    .file-label:not(.disabled):hover {
        cursor: pointer;
    }
}

label.file-label {
    width: 100%;
    color: grey;
    font-size: 1.0rem;
    margin-bottom: 0;
}

.checkbox-wrapper {
    font-size: 1.0rem;

    input {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        margin-right: 0.5rem;

        &:hover {
            cursor: pointer;
        }
    }

    label:hover {
        cursor: pointer;
    }
}

.picture-wrapper {
    width: 120px;
    border: 1px solid $inputBorderThinColor;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-top: -30px;
    margin-bottom: 30px;

    .picture {
        width: 100%;
        text-align: center;
    }
}

.big-radio {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    margin-right: 0.5rem;
}

.error-message {
    color: $errorColor;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.white-link {
    color: white;

    &:hover {
        color: white;
        text-decoration: none;
    }
}

.login-card {
    width: 720px;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;

    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.card-hr {
    border-top: 1px solid #E0E0E0;
}

.btn-ellipse-blue {
    color: white;
    background-color: #005ead;
    border-color: none;
    width: 100%;
    border-radius: 100vh;
    padding: 5px 15px;
}

.blue-card {
    background-color: $backgroundColor;
    color: $navBarColor;
    font-size: 1.2rem;
    margin-bottom: 2.0rem;
}


#hamburger {
    font-size: 1.1rem;

    &:hover {
        cursor: pointer;
    }
}

#side-bar {
    position: fixed;
    background-color: $backgroundColor;
    width: $sideBarWidth;
    height: 100%;
}

#main {
    padding-left: $sideBarWidth;
    width: 100%;
    height: 100%;
}

.no-marker-list {
    list-style-type: none;
    padding: 10px 0px;
    a {
        font-size: 1.0rem;
        color: black;
    }
}

.sub-card {
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 1.25rem 1.75rem 1.25rem 1.0rem;
}

.right-card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.blue-card {
    background-color: white;
}

.blue-card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 0.5rem;
    font-size: 16px;
    background-color: $buttonCollor;
    color: white;
}

.blue-card-body {
    background-color: $backgroundColor;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.blue-card-element {
    height: 2.5rem;
    font-size: 14.4px;
    width: 100%;
    color: $fontColor;
    display: block;
    padding-left: 1.0rem;
    padding-right: 1.0rem;
    line-height: 2.5rem;

    &:hover {
        background-color: $hoverColor;
        text-decoration: none;
        color: $fontColor;
    }
}

.card-title {
    font-size: 1.25rem;
    font-weight: 700;
}

#form-button-wrapper {
    position: fixed;
    backdrop-filter: blur(5px);
    background-color: rgba(70, 70, 70, 0.2);
    bottom: 0;
    width: 100%;
    height: 6.0rem;
}

#form-button-inner {
    padding-left: 2.0rem;
    padding-right: 300px;
    display: flex;
    align-items: center;
    height: 100%;
}

#cancel-button {
    @extend .btn-base;
    background-color: white;
    border-radius: 4px;
    font-weight: 500;
    height: 2.0rem;
    width: 240px;

    & > i {
        color: $fontColor;
    }
}

#register-button {
    @extend .btn-base;
    color: white;
    background-color: $buttonCollor;
    border-radius: 4px;
    font-weight: 500;
    height: 2.0rem;
    width: 240px;

    & > i {
        color: white;
    }
}

.sub-cancel-button {
    @extend .btn-base;
    border-radius: 4px;
    font-weight: 500;
    height: 2.0rem;
    background-color: $subCancelColor;
}

.main-button {
    @extend .btn-base;
    color: white;
    background-color: $buttonCollor;
    border-radius: 4px;
    font-weight: 500;
    height: 2.0rem;
}

#modal-cancel-button {
    @extend #cancel-button;
    background-color: #eeeeee;
    width: 140px;
}

#modal-register-button {
    @extend #register-button;
    width: 140px;
}

#cannot-send-button {
    @extend .btn-base;
    color: white;
    background-color: $errorColor;
    border-radius: 4px;
    font-weight: 500;
    height: 2.0rem;
    width: 260px;
    
    & > i {
        color: white;
    }

    &:disabled {
        opacity: 0.5;
    }

    &:hover {
        cursor: default;
    }
}

.sub-caption {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.col-57 {
    flex: 0 0 47.5%;
    max-width: 47.5%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-24 {
    flex: 0 0 20%;
    max-width: 20%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.card-item {
    border-radius: 0.25rem;
    padding: 0.5rem;
    font-size: 16px;
    width: 100%;
    background-color: $buttonCollor;
    color: white;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 2.0rem;
    
    &:hover {
        text-decoration: none;
        color: white;
    }
}

.card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 0.5rem;
    font-size: 16px;
    width: $cardItemWidth;
    background-color: $buttonCollor;
    color: white;
}

#spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    color: $navBarColor;
    border-width: 4px;
    width: 4.0rem;
    height: 4.0rem;
    z-index: 100;
}