#sidebar-table_filter {
    margin-bottom: 1.5rem;

    label {
        width: 100%;
        padding-left: 1.0rem;
        padding-right: 1.0rem;
    }
    
    input {
        width: 100%;
        border: none;
        border-radius: 0px;
        border-bottom: 1px solid #cccccc;
        font-size: 1.0rem;
        outline: 0;
        background-color: transparent;
        transition: all 0.2s;
    
        &[type="date"] + .label {
            top: -15px;
            font-size: 0.8rem;
            line-height: 0.8rem;
        }
    
        &:focus {
            border-bottom: 1px solid $navBarColor;
    
            &:before {
                top: -15px;
                font-size: 0.8rem;
                line-height: 0.8rem;
                color: $navBarColor;
            }
        }
    }

}

#sidebar-table {
    background-color: $backgroundColor;

    .dataTables_empty {
        text-align: center;
    }
}

#sidebar-table_paginate a.paginate_button {
    margin-left: 0.2rem !important;
    margin-right: 0.2rem !important;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
    padding: 0 0.2rem !important;
}

#sidebar-table_paginate {
    text-align: center !important;
    width: 100%;

    a.paginate_button {
        display: inline-block;
        text-decoration: none;
        text-align: center !important;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        padding: 0.25rem !important;
        background: white !important;
        border: none !important;
        cursor: normal !important;
        
        &.current {
            background-color: $buttonCollor !important;
            cursor: normal !important;
            color: white !important;
            &:hover {
                cursor: normal !important;
                color: white !important;
            }
        }

        &.disabled {
            opacity: 0.5;
        }
    
        &:not(.current, .disabled):hover {
            cursor: pointer;
            color: inherit !important;
        }
    }
}
