.navbar {
    background-color: $navBarColor;
    color: white !important;
    padding: 0 !important;
}

.navbar-inner {
    height: $navBarHeight;
    padding-left: 1.0rem;
    padding-right: 1.0rem;
}

.navbar-brand {
    color: white;
    margin-left: 15px;
    display: inline-block;
    height: 100%;
}

.navbar-brand-image {
    height: inherit;

    &:hover {
        opacity: 0.7;
    }
}

.dropdown-toggle::after {
    display: none;
}

.header-extension {
    background-color: white;
    color: $fontColor;
    width: 100%;
    height: $headerExtensionHeight;
    line-height: $headerExtensionHeight;
}

.back-wrapper {
    width: $sideBarWidth;
    padding-left: 1.0rem;
    height: $headerExtensionHeight;
}

.back-anchor {
    position: relative;
    &:hover {
        text-decoration: none;
    }

    &:hover::before {
        position: absolute;
        left: 0;
        top: 2.0rem;
        font-size: 1.0rem;
        width: 4.0rem;
        text-align: center;
        background-color: #7B7C7E;
        border-radius: 4px;
        content: "戻る";
        color: white;
    }
}

.back-icon {
    font-size: 1.0rem;
    color: #888888;
    padding-right: 0.75rem;
}

.back-name {
    font-size: 1.1rem;
}

#mode {
    height: $headerExtensionHeight;
    width: 200px;
    transition: all 0.2s;
    
    &[data-mode="view"] {
        background-color: $viewModeColor;

        & > #mode-text:before {
            font-family: "Font Awesome 5 Free";
            content: "\f06e";

            font-weight: bold;
            padding-right: 0.5rem;
            color: $iconColor;
        }

        & > #mode-text:after {
            content: "閲覧モード";
        }
    }

    &[data-mode="edit"] {
        background-color: $editModeColor;

        & > #mode-text:before {
            font-family: "Font Awesome 5 Free";
            content: "\f304";

            font-weight: bold;
            padding-right: 0.5rem;
            color: $iconColor;
        }

        & > #mode-text:after {
            content: "編集モード";
        }
    }

    &[data-mode="add"] {
        background-color: $editModeColor;

        & > #mode-text:before {
            font-family: "Font Awesome 5 Free";
            content: "\f0fe";

            padding-right: 0.5rem;
            color: $iconColor;
        }

        & > #mode-text:after {
            content: "追加モード";
        }
    }
}

.header-extension-button-wrapper {
    height: $headerExtensionHeight;
}

.header-extension-button {
    border: none;
    background-color: white;
    padding-left: 1.0rem;
    padding-right: 1.0rem;
    color: #0c7edb;
    transition: all 0.3s ease;
    height: $headerExtensionHeight;

    &:active {
        background-color: #bcddf8;
    }

    &:disabled {
        color: #cccccc;
    }
}

.header-divider {
    margin: 0.5rem 1.0rem;
    border-left: 1px solid $inputBorderThinColor;
    max-width: 0px;
}